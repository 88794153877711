import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { Theme as ThemeInterface } from '@emotion/react';

type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
type FontWeight = 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';

interface TextProps {
  element?: TextElement;
  children: React.ReactNode;
  weight?: FontWeight;
  italic?: boolean;
  underlined?: boolean;
  small?: boolean;
  code?: boolean;
  inline?: boolean;
  color?: string;
}

const StyledText = styled.span<{
  textElement: TextElement;
  theme: ThemeInterface;
  weight?: FontWeight;
  italic?: boolean;
  underlined?: boolean;
  small?: boolean;
  code?: boolean;
  inline?: boolean;
  color?: string;
}>(({ theme, textElement, weight = 'regular', italic, underlined, small, code, inline, color }) => {
  const isParagraph = textElement === 'p';

  return {
    fontSize: isParagraph && small ? theme.font.fontSizes.xs : theme.text.size[textElement],
    fontWeight: isParagraph ? theme.font.weight[weight] ?? theme.text.weight['p'] : theme.text.weight[textElement],
    lineHeight: theme.text.lineHeight[textElement],
    fontStyle: isParagraph && italic ? 'italic' : 'none',
    textDecoration: isParagraph && underlined ? 'underline' : 'none',
    fontFamily: isParagraph && code ? theme.font.familyCode : theme.font.family,
    textTransform: textElement === 'h6' ? 'uppercase' : 'none',
    display: inline ? 'inline' : 'inherit',
    color: color ? color : 'inherit',
  };
});

const Text: FC<TextProps> = ({ element = 'p', weight, italic, underlined, small, code, inline, color, children }) => {
  const { theme } = useTectonTheme();

  return (
    <StyledText
      textElement={element}
      theme={theme}
      as={element}
      weight={weight}
      italic={italic}
      underlined={underlined}
      small={small}
      code={code}
      inline={inline}
      color={color}
    >
      {children}
    </StyledText>
  );
};

export default Text;

export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.69396 9.93079C4.50996 9.09413 3.79996 7.63346 4.04996 6.02546C4.32062 4.28746 5.76929 2.90013 7.51662 2.69546C9.94196 2.41079 12 4.29879 12 6.66679C12 8.01679 11.3293 9.20813 10.3046 9.93213C10.1206 10.0621 9.99996 10.2635 9.99996 10.4888V12.3335C9.99996 13.2541 9.25396 14.0001 8.33329 14.0001H7.66662C6.74596 14.0001 5.99996 13.2541 5.99996 12.3335V10.4915C5.99996 10.2641 5.87862 10.0615 5.69396 9.93079Z"
        stroke="#DB8500"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.332 3.33337L14.1254 2.54004"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66833 10L1.875 10.7933"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.3324 2.66638L2.53906 1.87305"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.332 10L14.1254 10.7933"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 6.66634H15.1267"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.875 6.66634H2.00167"
        stroke="#DB8500"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M6 12.0003H9.91333" stroke="#DB8500" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

import React, { FC } from 'react';

import { Badge, BadgeVariants } from '@tecton/ComponentRedesign';
import { FeatureViewFCO, FeatureViewFCOType } from '../../../core/types/fcoTypes';

interface FeatureViewTypeBadgeProps {
  featureView?: FeatureViewFCO;
  type?: FeatureViewFCOType;
}

export const typeLabelMap: Record<FeatureViewFCOType, string> = {
  [FeatureViewFCOType.FEATURE_TABLE]: 'Feature Table',
  [FeatureViewFCOType.REALTIME]: 'Realtime',
  [FeatureViewFCOType.STREAM]: 'Stream',
  [FeatureViewFCOType.BATCH]: 'Batch',
  [FeatureViewFCOType.UNKNOWN]: 'Unknown',
  [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: 'Stream Window Aggregate',
  [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: 'Batch Window Aggregate',
  [FeatureViewFCOType.PUSH_WITH_BATCH]: 'Stream',
  [FeatureViewFCOType.PUSH_NO_BATCH]: 'Stream',
};

const FeatureViewTypeBadge: FC<FeatureViewTypeBadgeProps> = ({ featureView, type }) => {
  const dataSourceTypeColorPairs: Record<FeatureViewFCOType, BadgeVariants> = {
    [FeatureViewFCOType.BATCH]: 'default',
    [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: 'hollow',
    [FeatureViewFCOType.STREAM]: 'custom',
    [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: 'primary',
    [FeatureViewFCOType.REALTIME]: 'accent',
    [FeatureViewFCOType.FEATURE_TABLE]: 'danger',
    [FeatureViewFCOType.UNKNOWN]: 'warning',
    [FeatureViewFCOType.PUSH_WITH_BATCH]: 'disabled',
    [FeatureViewFCOType.PUSH_NO_BATCH]: 'success',
  };

  if (featureView) {
    const colorPair = dataSourceTypeColorPairs[featureView.featureViewType];
    return (
      <>
        fdas
        <Badge value={colorPair} label={typeLabelMap[featureView.featureViewType]} />
      </>
    );
  }

  if (type) {
    const colorPair = dataSourceTypeColorPairs[type];
    return (
      <div style={{ pointerEvents: 'none' }}>
        fdsad <Badge variant={colorPair} label={typeLabelMap[type]} />
      </div>
    );
  }

  return <></>;
};

export default FeatureViewTypeBadge;

import {
  GetStateUpdatePlanSummaryResponse,
  QueryStateUpdateResponseV2,
} from '../../types/tecton_proto/metadataservice/metadata_service';
import { timeFormat } from 'd3-time-format';

export const transformPlanSummary = (data: GetStateUpdatePlanSummaryResponse) => {
  const plan = data.plan;

  // The terminology is wrong on the backend. This is the easiest way to handle that.
  // If FcoStrings.kt is properly updated, this can be removed. That fix won't impact this later.
  if (plan && plan!.diff_items) {
    plan!.diff_items = plan!.diff_items!.map((item) => {
      return {
        ...item,
        fco_type: item.fco_type === 'Realtime (On-Demand) Feature View' ? 'Realtime Feature View' : item.fco_type,
      };
    });
  }

  return {
    plan,
    ...(plan?.applied_at ? { appliedAt: timeFormat('%Y-%m-%d %H:%M:%S')(new Date(plan?.applied_at ?? '')) } : {}),
    ...(plan?.created_at ? { createdAt: timeFormat('%Y-%m-%d %H:%M:%S')(new Date(plan?.created_at ?? '')) } : {}),
    hasApplied: !!plan?.applied_by && !!plan?.applied_at, // has been applied
  };
};

export type StateUpdatePlanSummaryType = ReturnType<typeof transformPlanSummary>;

export const transformQueryStateUpdateResponse = (data: QueryStateUpdateResponseV2) => {
  return { ...data };
};
